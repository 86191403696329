$prefix: 'orders-list';

.#{$prefix} {
	&__amount {
		@apply text-tsecondary ml-4;
	}
	@apply container mx-auto px-6 xl:px-8 mb-5;
	&__table-container {
		@apply mt-5;
	}
	&__table-head {
		@apply flex flex-col justify-between;
	}
	&__filters {
		@apply flex flex-col mb-5 gap-3;
	}
	&__filters-fields {
		@apply flex;
	}
}
