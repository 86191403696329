$prefix: 'ez-table';

.#{$prefix} {
	&__body {
		@apply w-full flex flex-col justify-between overflow-auto;
		min-height: 14rem;
		border-left: solid 2px rgb(242, 242, 242);
		border-right: solid 2px rgb(242, 242, 242);
		border-top: solid 2px rgb(242, 242, 242);
		border-top-right-radius: 0.5rem;
		border-top-left-radius: 0.5rem;
		&--scrolled {
			.ez-table__heading--sticky:nth-child(2), .ez-table-item--sticky:nth-child(2) {
				&::after {
					box-shadow: 3px 0px 5px -2px rgba(0,0,0,0.1);
					content: "";
					position: absolute;
					width: 1rem;
					height: 100%;
					top: 0;
					bottom: 0;
					right: 0;
				}
			}
		}
	}
	&__table {
		@apply w-full border-separate;
	}
	.table th:first-child {
		z-index: 0;
	}
	&__heading {
		&--sticky:nth-child(1) {
			@apply lg:sticky left-0 bg-base-100;
			// box-shadow: 5px 0px 15px -3px rgba(0,0,0,0.1);
		}
		&--sticky:nth-child(2) {
			@apply lg:sticky bg-base-100;
		}
	}
	&__row {
		.ez-table-item:nth-child(1) {
			font-weight: 600;
		}
	}
	&__not-found-message {
		@apply flex p-4;
	}
}
