$prefix: 'revenue-form-modal';

.#{$prefix} {
  &__months {
    justify-content: space-between;
    width: 100%;
    & > * {
      width: 30%;
    }
  }
}