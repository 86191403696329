$prefix: "ez-menu";

.#{$prefix} {
	&__item {
		@apply h-10 flex transition-all after:z-50 gap-6 text-tsecondary;
		&--active {
			@apply bg-primarylight text-primary;
		}
		&:active {
			@apply bg-primarylight;
		}
	}
}
