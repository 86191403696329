$prefix: 'clients-order-per-month';

.#{$prefix} {
  &__label {
    @apply uppercase font-semibold mb-3;
  }
  &__card-body {
    @apply card-body flex w-full;
  }
  &__container {
    @apply flex flex-row;
  }
}
