$prefix: 'targets';

.#{$prefix} {
	&__container {
		@apply container mx-auto flex flex-col px-6 xl:px-8 mb-5;
	}
	&__title {
		@apply text-3xl breadcrumbs font-bold;
	}
	&__tooltip {
		@apply flex my-5 justify-start;
	}
	&__target-cell {
		@apply text-center;
	}
	&__target-value {
		@apply font-bold;
	}
}
