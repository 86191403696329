$prefix: 'ez-input-autocomplete';

.#{$prefix} {
	@apply relative grow;
	&__text-box {
		@apply input input-bordered w-full flex max-w-xl;
	}
	&__floating-box {
		@apply menu mt-1 bg-base-100 rounded-b-2xl absolute shadow-xl left-0 right-0 overflow-y-auto max-h-60 flex-nowrap z-20;
		li {
			width: 100%;
		}
	}
	&__min-characters-box {
		@apply flex p-3 font-semibold;
	}
	&__item {
		@apply gap-0 flex items-start justify-start flex-wrap;
		&--hovered {
			background-color: hsl(var(--bc) / var(--tw-bg-opacity));
			--tw-bg-opacity: 0.1;
		}
	}
	&__spinner-container {
		@apply flex justify-center p-3;
	}
	&__spinner {
		span {
			@apply bg-primary;
		}
	}
}
