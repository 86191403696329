$prefix: 'ez-toolbar';

.#{$prefix} {
  display: flex;
  flex-direction: column;

  &__toolbar {
    @apply flex justify-between grow mb-3;
  }

  &__content-start {
    @apply flex gap-2;
  }
}