$prefix: 'ez-nav-group';

.#{$prefix} {
	@apply tabs flex mt-3;
	&::after {
		@apply w-full h-0.5 -top-0.5 relative z-0 bg-base-300;
		content: "";
	}
	&__nav-item {
		@apply tab tab-bordered mt-5 font-semibold duration-500;
		&:hover {
			@apply text-primary z-[1] border-primary border-b-2;
		}
	}
	&__nav-item-active {
		@apply tab tab-bordered z-[1] border-primary text-primary font-semibold;
	}
	&__content {
		@apply flex;
	}
}
