$prefix: 'login';

.#{$prefix} {
	@apply flex flex-row card shadow w-full h-full max-w-lg lg:max-w-4xl bg-base-100 max-h-[36rem] rounded-md;
	&__left-section {
		background: linear-gradient(0deg, rgba(48, 102, 190, 0.8), rgba(48, 102, 190, 0.8)), URL('../../assets/images/work.jpg');
		@apply w-1/2 hidden lg:flex items-center justify-center rounded-l-[inherit] bg-no-repeat bg-cover bg-center;
	}
	&__title {
		@apply text-white text-5xl;
	}
	&__form-section {
		@apply w-full lg:w-1/2 flex flex-col justify-center items-center px-10 lg:px-20;
	}
	&__form-title {
		@apply font-bold text-3xl text-center;
	}
	&__form-subtitle {
		@apply mt-2 text-center;
	}
	&__fields {
		@apply flex flex-col gap-3 w-full justify-between;
	}
	&__forgot-password {
		@apply text-right;
	}
	&__spinner {
		span {
			background: #fff;
		}
	}
}
