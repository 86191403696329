$prefix: "calendar-spd";

.#{$prefix} {
	&__heading-day {
		@apply lg:flex w-6 lg:w-auto font-semibold uppercase lg:text-center text-tprimary text-xs lg:text-sm;
		&--is-weekend {
			@apply text-error;
		}
	}
}

#calendar-spd {
	@apply grow w-full;
}

.calendar-item {
	@apply flex flex-col p-1 py-5 grow w-full h-10 justify-center items-center;
	&__day {
		@apply text-center text-xs md:text-base w-6 md:w-4 font-bold w-full;
	}
	&__value {
		@apply flex text-xs lg:text-base w-full lg:w-3/6 text-right items-center justify-center;
	}
	&--current-day {
		@apply bg-primarylight text-primary rounded-3xl h-14;
		&.calendar-item--is-weekend {
			@apply bg-errorlight text-error;
		}
	}

	&--is-weekend {
		.calendar-item__day {
			@apply text-error;
		}
	}
}

.week-sum {
	@apply flex flex-col md:flex-row p-1 py-5 grow w-full justify-center;
	&__value {
		@apply md:flex lg:text-base text-xs text-right font-bold lg:font-normal lg:w-16 justify-end;
	}
}
