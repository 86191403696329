$prefix: 'calendar-spd-pagination';

.#{$prefix} {
	@apply flex justify-between items-center p-2 border rounded-b-lg;
	border-color: #d2d2d2;
	&__content-start,
	&__content-end {
		@apply flex items-center;
		gap: 1rem;
	}
	&__select-num-items {
		@apply h-12;
	}
	&__select-num-items div {
		@apply text-base w-8;
	}
	&__indicator {
		@apply flex;
	}
	&__nav-arrows {
		display: flex;
		align-items: center;
	}
	&__arrow {
		@apply bg-transparent;
	}
}