$prefix: 'ez-table-item';

.ez-table__row .#{$prefix} {
	&__date-cell {
		@apply flex flex-col text-sm;
	}
	&--sticky:nth-child(1) {
		@apply lg:sticky left-0 bg-base-100;
	}
	&--sticky:nth-child(2) {
		@apply lg:sticky bg-base-100;
	}
}
