$prefix: 'ez-input';

.#{$prefix} {
	@apply form-control w-full max-w-xl;
	&__label {
		@apply label;
		span {
			@apply label-text;
		}
	}
	&__field {
		@apply w-full max-w-xl;
	}
	&__field::placeholder {
		@apply text-secondary;
	}
	&__amount-field {
		input {
			@apply w-full max-w-xl text-xl;
			&::placeholder {
				@apply text-xl font-semibold;
				line-height: 1.9999rem;
				position: relative;
				&::after {
					position: absolute;
					left: 1rem;
					top: 0.5rem;
					content: attr(data-placeholder);
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}
	}
	&__with-icon {
		@apply input-group border-l-0;
	}
	&__icon {
		@apply bg-base-100 border border-secondary border-r-0;
	}
	&__field-with-icon {
		@apply border-l-0;
	}
}
