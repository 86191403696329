$prefix: 'stats';

.#{$prefix} {
  &__container {
    @apply container mx-auto px-6 xl:px-8 my-5;
  }
  &__title {
    @apply text-3xl breadcrumbs font-bold;
  }
  &__content {
    @apply flex w-full mt-5;
  }
}
