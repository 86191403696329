$prefix: 'ew-sidenav';

.#{$prefix}-container {
	position: relative;
}

.#{$prefix} {
	display: flex;
	justify-content: space-between;
	flex-flow: column;
	position: fixed;
	z-index: 1;
	height: 100%;
	&__item {
		@apply h-10 flex transition-all after:z-50 gap-6 text-tsecondary;
		&--active {
			@apply bg-primarylight text-primary;
		}
	}

	&__avatar {
	}
	&__avatar-img {
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		@apply bg-primary;
	}

	&__username {
		align-items: center;
		@apply flex text-tsecondary;
	}
}
