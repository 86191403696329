$prefix: 'ez-success-feedback';

.#{$prefix} {
  @apply flex flex-col justify-center w-full text-center items-center;
  &__gif {
    @apply w-28;
  }
  &__title {
    @apply text-green-600 text-3xl;
  }
  &__message {
    @apply pt-3 text-xl;
  }
}
